body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

#tooltipConsent {
    left: -200px !important;
}

    #tooltipConsent .react-tooltip-arrow {
        display: none !important;
    }
