.img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 230px;
}

.left_container {
    margin-bottom: 390px;
}

.header_h1 {
    width: 505px;
}

.header_h6 {
    width: 348.276px;
}

@media (max-width: 1080px) {
    .img_container {
        margin: 10px 0px;
    }

    .left_container {
        margin-bottom: 0px;
    }
}

@media (max-width: 1400px) {
    .header_h1 {
        width: 100%;
    }

    .header_h6 {
        width: 100%;
    }
}
